import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'

export const NUTRITIONAL_KEYS = Object.freeze({
  recNutritionalInsufficientWeight: 'rec_nutritional_insufficient_weight',
  recNutritionalMediterranean: 'rec_nutritional_mediterranean',
  recNutritionalMediterraneanKeep: 'rec_nutritional_mediterranean_keep',
  recNutritionalHypertension: 'rec_nutritional_hypertension',
  recNutritionalHeartFailure: 'rec_nutritional_heart_failure',
  recNutritionalDiabetesType1: 'rec_nutritional_diabetes_type_1',
  recNutritionalDiabetesType2: 'rec_nutritional_diabetes_type_2',
  recNutritionalDyslipidemia: 'rec_nutritional_dyslipidemia',
  recNutritionalOsteoarthritisArthritis:
    'rec_nutritional_osteoarthritis_arthritis',
  recNutritionalOsteoporosis: 'rec_nutritional_osteoporosis',
  recLactoseIntolerant: 'rec_lactose_intolerant',
  recNutritionalIrritableBowelSyndrome:
    'rec_nutritional_irritable_bowel_syndrome',
  recNutritionalGastroesophagealReflux:
    'rec_nutritional_gastroesophageal_reflux',
  recNutritionalCeliacDisease: 'rec_nutritional_celiac_disease',
  recNutritionalGastritis: 'rec_nutritional_gastritis',
  recNutritionalUlcerativeColitis: 'rec_nutritional_ulcerative_colitis',
  recNutritionalCrohnsDisease: 'rec_nutritional_crohns_disease',
  recNutritionalConstipation: 'rec_nutritional_constipation',
  recNutritionalMediterraneanCopd: 'rec_nutritional_mediterranean_copd',
  recNutritionalTobaccoQuit: 'rec_nutritional_tobacco_quit',
  recNutritionalMediterraneanAnxiety: 'rec_nutritional_mediterranean_anxiety',
  recNutritionalMediterraneanDepression:
    'rec_nutritional_mediterranean_depression',
  recNutritionalMediterraneanSleep: 'rec_nutritional_mediterranean_sleep',
  recNutritionalOverweight: 'rec_nutritional_overweight',
  recNutritionalObesity: 'rec_nutritional_obesity',
  recNutritionalVegan: 'rec_nutritional_vegan',
  recNutritionalVegetarian: 'rec_nutritional_vegetarian',
  recNutritionalDysphagia: 'rec_nutritional_dysphagia',
  recNutritionalCombo1: 'rec_nutritional_combo_1', // (rec_nutritional_hypertension + rec_nutritional_obesity + rec_nutritional_obesity + rec_nutritional_dyslipidemia)
  recNutritionalCombo2: 'rec_nutritional_combo_2', // (rec_nutritional_hypertension + rec_nutritional_obesity)"
  recNutritionalCombo3: 'rec_nutritional_combo_3', // (rec_nutritional_hypertension + rec_nutritional_diabetes_type_2)"
  recNutritionalCombo4: 'rec_nutritional_combo_4', // (rec_nutritional_hypertension +rec_nutritional_dyslipidemia)"
  recNutritionalCombo5: 'rec_nutritional_combo_5', // (rec_nutritional_obesity + rec_nutritional_diabetes_type_2)"
  recNutritionalCombo6: 'rec_nutritional_combo_6', // (rec_nutritional_obesity + rec_nutritional_dyslipidemia)"
  recNutritionalCombo7: 'rec_nutritional_combo_7', // (rec_nutritional_diabetes_type_2 + rec_nutritional_dyslipidemia)"
  recNutritionalCombo0: 'rec_nutritional_combo_0', // (rec_nutritional_mediterranean_copd)"
  recNutritionalCombo8: 'rec_nutritional_combo_8', // (rec_nutritional_heart_failure + rec_nutritional_hypertension)"
  recNutritionalCombo9: 'rec_nutritional_combo_9', // (rec_nutritional_heart_failure + rec_nutritional_obesity)"
  recNutritionalCombo10: 'rec_nutritional_combo_10', // (rec_nutritional_heart_failure + rec_nutritional_diabetes_type_2)"
  recNutritionalCombo11: 'rec_nutritional_combo_11', // (rec_nutritional_heart_failure + rec_nutritional_dyslipidemia)"
})

export const NUTRITIONAL_KEY_GUIDELINES_RECOMMENDATIONS = (t, value) => {
  const reccomendationValue = (key) => {
    const baseKey = `sectionFour.keyGuidelines.recommendations.${key}`

    const baseKeyValue = `${baseKey}.${value}`

    const resolvedTranslation = t(baseKeyValue)

    const nutritionalTranslation = t(`initial-report:${resolvedTranslation}`)

    if (nutritionalTranslation !== resolvedTranslation) {
      return t(`initial-report:${resolvedTranslation}`)
    }
    return t(`initial-report:${baseKey}.common`)
  }

  return [
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.one.title" />
      ),
      text: (
        <TranscriptionScriptPDF
          script="initial-report:sectionFour.keyGuidelines.recommendations.one.text"
          scriptProps={{ value: reccomendationValue('one') }}
        />
      ),
      list: {
        description: (
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.one.list.description" />
        ),
        items: [
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.one.list.one" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.one.list.two" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.one.list.three" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.one.list.four" />,
        ],
      },
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('one')} />
      ),
    },
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.two.title" />
      ),
      text: (
        <TranscriptionScriptPDF
          script="initial-report:sectionFour.keyGuidelines.recommendations.two.text"
          scriptProps={{ value: reccomendationValue('two') }}
        />
      ),
      list: {
        description: (
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.two.list.description" />
        ),
        items: [
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.two.list.one" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.two.list.two" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.two.list.three" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.two.list.four" />,
        ],
      },
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('two')} />
      ),
    },
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.three.title" />
      ),
      text: (
        <TranscriptionScriptPDF
          script="initial-report:sectionFour.keyGuidelines.recommendations.three.text"
          scriptProps={{ value: reccomendationValue('three') }}
        />
      ),
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('three')} />
      ),
    },
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.four.title" />
      ),
      text: (
        <TranscriptionScriptPDF
          script="initial-report:sectionFour.keyGuidelines.recommendations.four.text"
          scriptProps={{ value: reccomendationValue('four') }}
        />
      ),
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('four')} />
      ),
    },
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.five.title" />
      ),
      text: (
        <TranscriptionScriptPDF
          script="initial-report:sectionFour.keyGuidelines.recommendations.five.text"
          scriptProps={{ value: reccomendationValue('five') }}
        />
      ),
      tips: {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.five.tips.title" />
        ),
        items: [
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.five.tips.one" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.five.tips.two" />,
        ],
      },
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('five')} />
      ),
    },
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.six.title" />
      ),
      text: (
        <TranscriptionScriptPDF
          script="initial-report:sectionFour.keyGuidelines.recommendations.six.text"
          scriptProps={{ value: reccomendationValue('six') }}
        />
      ),
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('six')} />
      ),
    },
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.seven.title" />
      ),
      text: (
        <TranscriptionScriptPDF
          script="initial-report:sectionFour.keyGuidelines.recommendations.seven.text"
          scriptProps={{ value: reccomendationValue('seven') }}
        />
      ),
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('seven')} />
      ),
    },
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.eight.title" />
      ),
      text: (
        <TranscriptionScriptPDF
          script="initial-report:sectionFour.keyGuidelines.recommendations.eight.text"
          scriptProps={{ value: reccomendationValue('eight') }}
        />
      ),
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('eight')} />
      ),
    },
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.nine.title" />
      ),
      text: (
        <TranscriptionScriptPDF
          script="initial-report:sectionFour.keyGuidelines.recommendations.nine.text"
          scriptProps={{ value: reccomendationValue('nine') }}
        />
      ),
      tips: {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.nine.tips.title" />
        ),
        items: [
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.nine.tips.one" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.nine.tips.two" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.nine.tips.three" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.nine.tips.four" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.nine.tips.five" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.nine.tips.six" />,
        ],
      },
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('nine')} />
      ),
    },
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.ten.title" />
      ),
      text: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.ten.text" />
      ),
      tips: {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.ten.tips.title" />
        ),
        items: [
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.ten.tips.one" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.ten.tips.two" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.ten.tips.three" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.ten.tips.four" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.ten.tips.five" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.ten.tips.six" />,
        ],
      },
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('ten')} />
      ),
    },
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.eleven.title" />
      ),
      text: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.eleven.text" />
      ),
      tips: {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.eleven.tips.title" />
        ),
        items: [
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.eleven.tips.one" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.eleven.tips.two" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.eleven.tips.three" />,
          <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.eleven.tips.four" />,
        ],
      },
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('eleven')} />
      ),
    },
    {
      title: (
        <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.recommendations.twelve.title" />
      ),
      text: (
        <TranscriptionScriptPDF
          script="initial-report:sectionFour.keyGuidelines.recommendations.twelve.text"
          scriptProps={{ value: reccomendationValue('twelve') }}
        />
      ),
      recommendation: (
        <TranscriptionScriptPDF script={reccomendationValue('twelve')} />
      ),
    },
  ]
}
export const NUTRITIONAL_KEY_GUIDELINES_RECOMMENDATIONS_SPECIFIC =
  Object.freeze({
    [NUTRITIONAL_KEYS.rec_nutritional_vegan]: [
      <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.additionalTips.one" />,
      <TranscriptionScriptPDF script="initial-report:sectionFour.keyGuidelines.additionalTips.rec_nutritional_mediterranean.one" />,
    ],
  })

const Links = Object.freeze({
  nutritionalLinkOne: 0,
  nutritionalLinkTwo: 1,
  nutritionalLinkThree: 2,
  nutritionalLinkFour: 3,
  nutritionalLinkFive: 4,
  nutritionalLinkSix: 5,
  nutritionalLinkSeven: 6,
  nutritionalLinkEight: 7,
  nutritionalLinkNine: 8,
})

export const NUTRITIONAL_ACTIVITY_LINKS = Object.freeze({
  [NUTRITIONAL_KEYS.recNutritionalMediterranean]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
    Links.nutritionalLinkFive,
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanKeep]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
    Links.nutritionalLinkFive,
  ],
  [NUTRITIONAL_KEYS.recNutritionalHypertension]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkSix,
    Links.nutritionalLinkSeven,
  ],
  [NUTRITIONAL_KEYS.recNutritionalHeartFailure]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkSix,
    Links.nutritionalLinkSeven,
  ],
  [NUTRITIONAL_KEYS.recNutritionalDiabetesType1]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkSix,
    Links.nutritionalLinkSeven,
  ],
  [NUTRITIONAL_KEYS.recNutritionalDiabetesType2]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkSix,
    Links.nutritionalLinkSeven,
  ],
  [NUTRITIONAL_KEYS.recNutritionalDyslipidemia]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkSix,
    Links.nutritionalLinkSeven,
  ],
  [NUTRITIONAL_KEYS.recNutritionalOsteoarthritisArthritis]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkSix,
    Links.nutritionalLinkSeven,
  ],
  [NUTRITIONAL_KEYS.recNutritionalOsteoporosis]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkSix,
    Links.nutritionalLinkSeven,
  ],
  [NUTRITIONAL_KEYS.recLactoseIntolerant]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkEight,
  ],
  [NUTRITIONAL_KEYS.recNutritionalIrritableBowelSyndrome]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkEight,
  ],
  [NUTRITIONAL_KEYS.recNutritionalGastroesophagealReflux]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkEight,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCeliacDisease]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkEight,
  ],
  [NUTRITIONAL_KEYS.recNutritionalGastritis]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkEight,
  ],
  [NUTRITIONAL_KEYS.recNutritionalUlcerativeColitis]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkEight,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCrohnsDisease]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkEight,
  ],
  [NUTRITIONAL_KEYS.recNutritionalConstipation]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkSix,
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanCopd]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalTobaccoQuit]: [],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanAnxiety]: [],
  // ignorar
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanDepression]: [],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanSleep]: [],
  [NUTRITIONAL_KEYS.recNutritionalInsufficientWeight]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  // ignorar
  [NUTRITIONAL_KEYS.recNutritionalOverweight]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
    Links.nutritionalLinkFive,
  ],
  [NUTRITIONAL_KEYS.recNutritionalObesity]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
    Links.nutritionalLinkFive,
  ],
  [NUTRITIONAL_KEYS.recNutritionalVegan]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalVegetarian]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalDysphagia]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
   // ignorar
  [NUTRITIONAL_KEYS.recNutritionalCombo1]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo2]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo3]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo4]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo5]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo6]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo7]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo0]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo8]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo9]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo10]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo11]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
  ],
});

export const NUTRITIONAL_ACTIVITY_WORKSHEET = Object.freeze({
  [NUTRITIONAL_KEYS.recNutritionalMediterranean]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanKeep]: [],
  [NUTRITIONAL_KEYS.recNutritionalHypertension]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalHeartFailure]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalDiabetesType1]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalDiabetesType2]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalDyslipidemia]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalOsteoarthritisArthritis]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalOsteoporosis]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recLactoseIntolerant]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalIrritableBowelSyndrome]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalGastroesophagealReflux]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCeliacDisease]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalGastritis]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalUlcerativeColitis]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCrohnsDisease]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalConstipation]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanCopd]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalTobaccoQuit]: [],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanAnxiety]: [],
  // ignorar
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanDepression]: [],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanSleep]: [],
  [NUTRITIONAL_KEYS.recNutritionalInsufficientWeight]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  // ignorar
  [NUTRITIONAL_KEYS.recNutritionalOverweight]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalObesity]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalVegan]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalVegetarian]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalDysphagia]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
   // ignorar
  [NUTRITIONAL_KEYS.recNutritionalCombo1]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo2]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo3]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo4]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo5]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo6]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo7]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo0]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo8]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo9]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo10]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo11]: [
    Links.nutritionalLinkOne,
    Links.nutritionalLinkTwo,
    Links.nutritionalLinkThree,
    Links.nutritionalLinkFour,
  ],
});