import React from 'react'

import ResultsDescription from '../components/ResultsDescription'
import { useTranslation } from 'react-i18next'
import useFetchUserInfo from '../hooks/useFetchUserInfo'

const EmotionalSR: React.FC = () => {
  const { analysisData, loading, error } = useFetchUserInfo()

  const { t } = useTranslation()

  const yAxisLabels = t('myEvolution.emotional.chart.yAxisLabels', {
    returnObjects: true,
  }) as string[]

  return (
    <div className="flex h-full gap-6 justify-start flex-1 pt-8 pb-6 px-6  w-full">
      <div className="flex flex-col w-full max-w-[830px]">
        <ResultsDescription
          data={analysisData}
          scoreKey="GOLDBERG_ANXIETY"
          scoreKey2="GOLDBERG_DEPRESSION"
          description={t(
            'myEvolution.emotional.resultsDescription.description',
          )}
          title={t('myEvolution.emotional.resultsDescription.title')}
          modalLink={t('myEvolution.emotional.resultsDescription.modalLink')}
          chartTitle={t('myEvolution.emotional.resultsDescription.chartTitle')}
          chartDescription={t(
            'myEvolution.emotional.resultsDescription.chartDescription',
          )}
          chartDescription2={t(
            'myEvolution.emotional.resultsDescription.chartDescription2',
          )}
          lowLabel={t('myEvolution.emotional.chart.legend.low')}
          mediumLabel={t('myEvolution.emotional.chart.legend.medium')}
          highLabel={t('myEvolution.emotional.chart.legend.high')}
          modalDescription={t(
            'myEvolution.emotional.resultsDescriptionModal.description',
          )}
          modalTitle={t('myEvolution.emotional.resultsDescriptionModal.title')}
          modalButton={t(
            'myEvolution.emotional.resultsDescriptionModal.button',
          )}
          loading={loading}
          error={error}
          loadingText={t('myEvolution.emotional.resultsDescription.loading')}
          yAxisLabels={yAxisLabels}
        />
      </div>
    </div>
  )
}

export default EmotionalSR
