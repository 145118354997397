import React from 'react'

import ResultsDescription from '../components/ResultsDescription'
import { useTranslation } from 'react-i18next'

const SocialSR: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex h-full gap-6 justify-start flex-1 pt-8 pb-6 px-6  w-full">
      <ResultsDescription
        noGraphic={t('myEvolution.social.resultsDescription.noGraphic')}
      />
    </div>
  )
}

export default SocialSR
