import axios from 'axios'

const baseURL: string = process.env?.['NX_CUSTOMER_PORTAL_API_URL'] || ''

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  withCredentials: true,
  maxBodyLength: Infinity,
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response?.status === 401) {
      window.location.href = '/login'
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
