import { areaColorKey, tw } from '../../utils'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { FollowRecomendations } from '../../components/FollowRecomendations'
import { useReportPdfContext } from '../../report-pdf-provider'
import { getRecommendations } from '../../../report-pdf/utils'

export const SecondPage = () => {
  const areaColor = areaColorKey.blueGreen

  const { data, t, person, type } = useReportPdfContext()

  const TRANSLATION_BASE_KEY = 'initial-report:sectionCognitive'
  const defaultKey = 'rec_cognitive_pfeiffer_not_available'

  const { recommendations } = data.results.initialAnalysisList

  const objectives = getRecommendations({
    recKeys: recommendations?.COGNITIVE,
    generalKey: '',
    defaultKey,
  })

  const keepInMindKey = objectives[0].includes('diagnosed')
    ? 'diagnosed'
    : 'common'

  const keepInMindList =
    t(`${TRANSLATION_BASE_KEY}.keepInMind.${keepInMindKey}`, {
      returnObjects: true,
    }) || []

  const keepInMind = keepInMindList?.map((script) => {
    return <TranscriptionScriptPDF script={script} />
  })

  const links = [0, 1, 2, 3]
  const workSheet = [0, 1, 2, 3, 4, 5]

  return (
    <FollowRecomendations
      translateFrom={'sectionFive.linksAndWorkSheet'}
      style={tw('px-12')}
      areaColor={areaColor}
      linksAndWorkSheetTitle={t(
        `generic.linksAndWorkSheet.title.care`,
      )}
      linksAndWorkSheetText={
        <TranscriptionScriptPDF
          script={`sectionFive.linksAndWorkSheet.text`}
          scriptProps={{ patientName: person.name }}
        />
      }
      keepInMindList={keepInMind}
      followRecommendationsText={t(
        'sectionFive.linksAndWorkSheet.followRecommendations.text',
      )}
      omsText={t(
        'sectionFive.linksAndWorkSheet.followRecommendations.omsText',
      )}
      workSheetList={workSheet}
      links={links}
      worksheetId={`worksheet-cognitive`}
    />
  )
}
