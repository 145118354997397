import { useState, useCallback, useLayoutEffect } from 'react';

const usePadding = () => {
  const [padding, setPadding] = useState({ left: 50, right: 50 });

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    if (width === 390) {
      setPadding({ left: 40, right: 20 });
    } else if (width === 744) {
      setPadding({ left: 40, right: 40 });
    } else if (width > 1439) {
      setPadding({ left: 40, right: 90 });
    }
  }, []);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return padding;
};

export default usePadding;