import { t } from 'i18next'
import EvolutionIMCBarChart from './Charts/EvolutionIMCBarChart'
import EvolutionBarChart from './Charts/EvolutionBarChart'

interface NutritionalChartsProps {
  handleModalToggle2: () => void
  handleModalToggle: () => void
  imcTitle: string
  modalLink: string
  imcChartDescription: string
  error: unknown
  data: any
  imcLegendLow: string
  imcLegendMedium: string
  imcLegendHigh: string
  scoreKey: string
  lowLabel: string
  mediumLabel: string
  highLabel: string
  yAxisLabels: string[]
  title: string
  chartTitle: string
  chartDescription: string
  noScoresInChart: boolean
  noScoresInChart2: boolean
}

const nutritionalCharts = ({
  handleModalToggle2,
  handleModalToggle,
  imcTitle,
  modalLink,
  imcChartDescription,
  error,
  data,
  imcLegendLow,
  imcLegendMedium,
  imcLegendHigh,
  title,
  chartTitle,
  chartDescription,
  scoreKey,
  lowLabel,
  mediumLabel,
  highLabel,
  yAxisLabels,
  noScoresInChart,
  noScoresInChart2,
}: NutritionalChartsProps) => {
  return (
    <div className="flex flex-col gap-8">
      {noScoresInChart ? null : (
        <div className="flex flex-col w-full bg-neutral-100 rounded-lg p-6 lg:p-10 min-h-[638px] font-aeonik text-generic-neutral-900">
          <h3 className="text-2xl mb-4 text-[#333333]">{imcTitle}</h3>
          <h4
            className="underline mb-4 text-xl cursor-pointer"
            onClick={handleModalToggle2}
          >
            {modalLink}
          </h4>
          <h3 className="text-2xl">{chartTitle}</h3>
          <h5 className="text-base my-4">{imcChartDescription}</h5>
          {error ? (
            <div className="flex justify-center items-center h-full text-center">
              <p>
                {t('myEvolution.resultsDescription.fetchError1')} <br />
                {t('myEvolution.resultsDescription.fetchError2')}
              </p>
            </div>
          ) : (
            <div className="flex flex-col">
              <EvolutionIMCBarChart
                data={data}
                imcLegendLow={imcLegendLow || ''}
                imcLegendMedium={imcLegendMedium || ''}
                imcLegendHigh={imcLegendHigh || ''}
              />
            </div>
          )}
        </div>
      )}
      {noScoresInChart2 ? null : (
        <div className="flex flex-col w-full bg-neutral-100 rounded-lg p-6 lg:p-10 min-h-[622px] font-aeonik text-generic-neutral-900">
          <h3 className="text-2xl mb-4 text-[#333333]">{title}</h3>
          <h4
            className="underline mb-4 text-lg lg:text-xl cursor-pointer"
            onClick={handleModalToggle}
          >
            {modalLink}
          </h4>
          <h4 className="mb-4 text-2xl">{chartTitle}</h4>
          <h5 className="mb-5 text-base">{chartDescription}</h5>
          {error ? (
            <div className="flex justify-center items-center h-full text-center">
              <p>
                {t('myEvolution.resultsDescription.fetchError1')} <br />
                {t('myEvolution.resultsDescription.fetchError2')}
              </p>
            </div>
          ) : (
            <div className="flex flex-col gap-8">
              <EvolutionBarChart
                data={data}
                scoreKey={scoreKey}
                lowLabel={lowLabel}
                mediumLabel={mediumLabel}
                highLabel={highLabel}
                yAxisLabels={yAxisLabels || []}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default nutritionalCharts
