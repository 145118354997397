import React from 'react'
import ResultsDescription from '../components/ResultsDescription'

const CognitiveSR: React.FC = () => {
  return (
    <div className="flex h-full gap-6 justify-start flex-1 pt-8 pb-6 px-6  w-full">
      <div className="flex flex-col w-full max-w-[830px]">
        <ResultsDescription />
      </div>
    </div>
  )
}

export default CognitiveSR
