import { useTranslation } from 'react-i18next'
import EvolutionBarChart from './Charts/EvolutionBarChart'
import { AnalysisHistoricalResults } from '../types'
import EvolutionTobaccoAndAlcoholChart from './Charts/EvolutionTobaccoAndAlcoholChart'

interface GenericChartsProps {
  handleModalToggle: () => void
  handleModalToggle2: () => void
  handleModalToggle3: () => void
  title: string
  title2: string
  title3: string
  modalLink: string
  chartTitle: string
  chartDescription: string
  error: unknown
  data: AnalysisHistoricalResults | null
  scoreKey: string
  lowLabel: string
  mediumLabel: string
  highLabel: string
  yAxisLabels: string[]
  scoreKey2: string
  chartDescription2: string
  scoreKey3: string
  chartDescription3: string
  noScoresInChart: boolean
  noScoresInChart2: boolean
  noScoresInChart3: boolean
}

const TobaccoAndAlcoholCharts = ({
  handleModalToggle,
  handleModalToggle2,
  handleModalToggle3,
  title,
  title2,
  title3,
  modalLink,
  chartTitle,
  chartDescription,
  error,
  data,
  scoreKey,
  lowLabel,
  mediumLabel,
  highLabel,
  yAxisLabels,
  scoreKey2,
  chartDescription2,
  scoreKey3,
  chartDescription3,
  noScoresInChart,
  noScoresInChart2,
  noScoresInChart3,
}: GenericChartsProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-6">
      {noScoresInChart ? null : (
        <div className="flex flex-col w-full bg-neutral-100 rounded-lg p-6 lg:p-10 min-h-[653px] font-aeonik text-generic-neutral-900">
          <h3 className="text-2xl mb-4 text-[#333333]">{title}</h3>
          <h4
            className="underline mb-4 text-xl cursor-pointer"
            onClick={handleModalToggle}
          >
            {modalLink}
          </h4>
          <h4 className="mb-4 text-2xl">{chartTitle}</h4>
          <h5 className="mb-5 text-base">{chartDescription}</h5>
          {error ? (
            <div className="flex justify-center items-center h-full text-center">
              <p>
                {t('myEvolution.resultsDescription.fetchError1')} <br />
                {t('myEvolution.resultsDescription.fetchError2')}
              </p>
            </div>
          ) : (
            <EvolutionTobaccoAndAlcoholChart
              data={data}
              scoreKey={scoreKey || ''}
              lowLabel={lowLabel}
              mediumLabel={mediumLabel}
              highLabel={highLabel}
              yAxisLabels={yAxisLabels || []}
            />
          )}
        </div>
      )}
      {noScoresInChart2 ? null : (
        <div className="flex flex-col w-full bg-neutral-100 rounded-lg p-6 lg:p-10 min-h-[653px] font-aeonik text-generic-neutral-900">
          <h3 className="text-2xl mb-4 text-[#333333]">{title2}</h3>
          <h4
            className="underline mb-4 text-lg lg:text-xl cursor-pointer"
            onClick={handleModalToggle2}
          >
            {modalLink}
          </h4>
          <h4 className="mb-4 text-2xl">{chartTitle}</h4>
          <h5 className="mb-5 text-base">{chartDescription2}</h5>
          {error ? (
            <div className="flex justify-center items-center h-full text-center">
              <p>
                {t('myEvolution.resultsDescription.fetchError1')} <br />
                {t('myEvolution.resultsDescription.fetchError2')}
              </p>
            </div>
          ) : (
            <EvolutionTobaccoAndAlcoholChart
              data={data}
              scoreKey={scoreKey2 || ''}
              lowLabel={lowLabel}
              mediumLabel={mediumLabel}
              highLabel={highLabel}
              yAxisLabels={yAxisLabels || []}
            />
          )}
        </div>
      )}
      {noScoresInChart3 ? null : (
        <div className="flex flex-col w-full bg-neutral-100 rounded-lg p-6 lg:p-10 min-h-[653px] font-aeonik text-generic-neutral-900">
          <h3 className="text-2xl mb-4 text-[#333333]">{title3}</h3>
          <h4
            className="underline mb-4 text-lg lg:text-xl cursor-pointer"
            onClick={handleModalToggle3}
          >
            {modalLink}
          </h4>
          <h4 className="mb-4 text-2xl">{chartTitle}</h4>
          <h5 className="mb-5 text-base">{chartDescription3}</h5>
          {error ? (
            <div className="flex justify-center items-center h-full text-center">
              <p>
                {t('myEvolution.resultsDescription.fetchError1')} <br />
                {t('myEvolution.resultsDescription.fetchError2')}
              </p>
            </div>
          ) : (
            <EvolutionTobaccoAndAlcoholChart
              data={data}
              scoreKey={scoreKey3 || ''}
              lowLabel={lowLabel}
              mediumLabel={mediumLabel}
              highLabel={highLabel}
              yAxisLabels={yAxisLabels || []}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default TobaccoAndAlcoholCharts
