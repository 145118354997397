import React from 'react'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
  ReferenceLine,
} from 'recharts'
import { formatDate } from '../../utils/chartUtils'
import { AnalysisHistoricalResults, ScoreData } from '../../types'
import usePadding from '../../hooks/useChartBarsPositions'

interface EvolutionIMCBarChartProps {
  data: AnalysisHistoricalResults | null
  imcLegendLow: string
  imcLegendMedium: string
  imcLegendHigh: string
}

const EvolutionIMCBarChart: React.FC<EvolutionIMCBarChartProps> = ({
  data,
  imcLegendLow,
  imcLegendMedium,
  imcLegendHigh,
}) => {
  const reference = [
    { label: 'Insuficiente', lower: 14, upper: 18.4, color: '#B3261E' },
    { label: 'Saludable', lower: 18.5, upper: 24.9, color: '#226F54' },
    { label: 'Sobrepeso I', lower: 25, upper: 26.9, color: '#F7E06D' },
    { label: 'Sobrepeso II', lower: 27, upper: 29.9, color: '#F7E06D' },
    { label: 'Obesidad T1', lower: 30, upper: 34.9, color: '#F7E06D' },
    { label: 'Obesidad TII', lower: 35, upper: 39.9, color: '#B3261E' },
    { label: 'Obesidad TIII', lower: 40, upper: 49.9, color: '#B3261E' },
    { label: 'Obesidad TIV', lower: 50, upper: 54.9, color: '#B3261E' },
  ]

  const yAxisLabels = reference.map((ref) => ref.label)

  const padding = usePadding()

  const renderCustomLegend = () => {
    return (
      <div className="mt-4">
        <ul className="flex flex-col gap-1 list-none p-0 font-aeonik text-generic-neutral-900">
          <li className="flex items-center">
            <div className="w-4 h-4 bg-error mr-4 rounded-sm" />
            {imcLegendLow}
          </li>
          <li className="flex items-center">
            <div className="w-4 h-4 bg-warning mr-4 rounded-sm" />
            {imcLegendMedium}
          </li>
          <li className="flex items-center">
            <div className="w-4 h-4 bg-success mr-4 rounded-sm" />
            {imcLegendHigh}
          </li>
        </ul>
      </div>
    )
  }

  const getBarColor = (score: number) => {
    const ref = reference.find((r) => score >= r.lower && score <= r.upper)
    return ref ? ref.color : '#808080'
  }

  const minScore = 14
  const maxScore = 54
  const numberOfLines = reference.length
  const interval = (maxScore - minScore) / numberOfLines

  const sortedData = data?.data?.attributes?.scores
    ?.find((s: ScoreData) => s.name === 'BMI')
    ?.data.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    )

  const validData = sortedData ? sortedData.slice(-3) : []

  if (!validData.length) {
    console.warn(`IMC no está disponible o no tiene datos.`)
  }

  return (
    <div className="w-full sm:w-[60%]">
      <ResponsiveContainer height={450} style={{ marginTop: '-12px' }}>
        <BarChart
          data={validData}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          <XAxis
            dataKey="date"
            tickFormatter={formatDate}
            scale="point"
            padding={padding}
            axisLine={true}
            tickLine={true}
          />

          <YAxis
            domain={[minScore, maxScore]}
            ticks={reference.map((_, index) => minScore + index * interval)}
            tickFormatter={(value, index) => yAxisLabels[index] || value}
            interval={0}
            tickCount={numberOfLines}
            width={120}
            axisLine={true}
            tickLine={true}
            tick={{ fontSize: 16, dy: -15 }}
          />

          {reference.map((ref, index) => (
            <ReferenceLine
              key={`ref-line-${index}`}
              y={minScore + index * interval + 2}
              stroke={ref.color}
              strokeDasharray="4 4"
            />
          ))}

          <Tooltip
            formatter={(value) => `${value} pts`}
            labelFormatter={formatDate}
          />
          <Legend content={renderCustomLegend} />

          <Bar
            dataKey="score"
            barSize={40}
            fillOpacity={1}
            stroke="none"
            name="Puntuación"
          >
            {data?.data?.attributes?.scores
              ?.find((s: ScoreData) => s.name === 'BMI')
              ?.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getBarColor(entry.score)} />
              ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default EvolutionIMCBarChart
