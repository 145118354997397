import { useState, useEffect } from 'react';
import axiosInstance from 'libs/shared/data-access/src/lib/clients/axiosClient/axiosConfig';
import { AnalysisHistoricalResults } from '../types';

const useFetchUserInfo = () => {
  const [clientData, setClientData] = useState(null);
  const [analysisData, setAnalysisData] = useState<AnalysisHistoricalResults | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const baseURL = process.env?.['NX_SOCIAL_REFERENT_API_URL'] || '';
  
      try {
        const match = window.location.pathname.match(/user-area\/(.*?)\/monitoring-area/);
        const policyID = match?.[1];

        if (!policyID) {
          throw new Error('Policy ID not found in URL');
        }


        const clientResponse = await axiosInstance.get(
          `${baseURL}clients/?action=fetchClient&filter[principal_policy]=${policyID}&include=person`
        );
        const personId = clientResponse.data.included[0].id;
        setClientData(clientResponse.data);

        if (personId) {
          const analysisResponse = await axiosInstance.get(
            `${baseURL}persons/${personId}/analysis_historical_results/`
          );
          setAnalysisData(analysisResponse.data);
        }
      } catch (err) {
        setError('Error fetching user data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { clientData, analysisData, loading, error };
};

export default useFetchUserInfo;
