import { useEffect, useState } from 'react'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'

import { TabList } from '@shared/ui'
import { EvolutionTabs, TAB_LINK } from './enums'

export const Evolution = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('')
  const [tabList, setTabList] = useState([])

  useEffect(() => {
    // Inicializar la lista de tabs
    const defaultTabList = EvolutionTabs()
    setTabList(defaultTabList)

    // Detectar la ruta activa o establecer una por defecto
    const currentPath = location.pathname.split('/').pop() // Obtener el último segmento de la URL
    const isValidTab = Object.values(TAB_LINK).includes(currentPath)

    if (isValidTab) {
      setActiveTab(currentPath)
    } else {
      const defaultTab = 'physical_activity'
      setActiveTab(defaultTab)
      navigate(`${TAB_LINK[defaultTab]}`, { replace: true })
    }
  }, [location, navigate])

  const onChangeTab = (name) => {
    setActiveTab(name)
    navigate(`${TAB_LINK[name]}`)
  }

  return (
    <div className="mt-10">
      <TabList
        defaultActiveTab={activeTab}
        activeTab={activeTab}
        tabList={tabList}
        onClickTab={onChangeTab}
      />
      <Outlet />
    </div>
  )
}
