import React, { useEffect, useState } from 'react'

import ResultsDescription from '../components/ResultsDescription'
import { useTranslation } from 'react-i18next'
import useFetchUserInfo from '../hooks/useFetchUserInfo'

const PhysicalActivitySR: React.FC = () => {
  const { t } = useTranslation()

  const { analysisData, loading, error } = useFetchUserInfo()

  const yAxisLabels = t('myEvolution.physicalActivity.chart.yAxisLabels', {
    returnObjects: true,
  }) as string[]

  return (
    <div className="flex h-full gap-6 justify-start flex-1 pt-8 pb-6 px-6  w-full">
      <div className="flex flex-col w-full max-w-[830px]">
        <ResultsDescription
          data={analysisData}
          scoreKey="IPAQ"
          description={t(
            'myEvolution.physicalActivity.resultsDescription.description',
          )}
          title={t('myEvolution.physicalActivity.resultsDescription.title')}
          modalLink={t(
            'myEvolution.physicalActivity.resultsDescription.modalLink',
          )}
          chartTitle={t(
            'myEvolution.physicalActivity.resultsDescription.chartTitle',
          )}
          chartDescription={t(
            'myEvolution.physicalActivity.resultsDescription.chartDescription',
          )}
          lowLabel={t('myEvolution.physicalActivity.chart.legend.low')}
          mediumLabel={t('myEvolution.physicalActivity.chart.legend.medium')}
          highLabel={t('myEvolution.physicalActivity.chart.legend.high')}
          modalDescription={t(
            'myEvolution.physicalActivity.resultsDescriptionModal.description',
          )}
          modalTitle={t(
            'myEvolution.physicalActivity.resultsDescriptionModal.title',
          )}
          modalButton={t(
            'myEvolution.physicalActivity.resultsDescriptionModal.button',
          )}
          loading={loading}
          error={error}
          loadingText={t(
            'myEvolution.physicalActivity.resultsDescription.loading',
          )}
          yAxisLabels={yAxisLabels}
        />
      </div>
    </div>
  )
}

export default PhysicalActivitySR
