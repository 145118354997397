import { areaColorKey } from '../../utils'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { FollowRecomendations } from '../../components/FollowRecomendations'
import { useReportPdfContext } from '../../report-pdf-provider'
import { useMemo } from 'react'

import {
  SOCIAL_KEYS,
  SOCIAL_ACTIVITY_LINKS,
  SOCIAL_ACTIVITY_WORKSHEET,
} from './enum'

export const SecondPage = () => {
  const { t, data, person, type } = useReportPdfContext()

  const areaColor = areaColorKey.red

  const keepInMind = [
    <TranscriptionScriptPDF script="initial-report:sectionSeven.linksAndWorkSheet.keepInMind.one" />,
    <TranscriptionScriptPDF script="initial-report:sectionSeven.linksAndWorkSheet.keepInMind.two" />,
  ]

  const { recommendations } = data?.results?.initialAnalysisList || {}

  const getSocial = () => {
    let activityIncluded = ''

    Object.values(SOCIAL_KEYS).forEach((activity) => {
      if (recommendations.SOCIAL.includes(activity)) {
        activityIncluded = activity
      }
    })

    return activityIncluded
  }

  const socialActivity = useMemo(() => {
    return getSocial()
  }, [recommendations])

  return (
    <FollowRecomendations
      translateFrom="sectionSeven.linksAndWorkSheet"
      areaColor={areaColor}
      linksAndWorkSheetTitle={t(
        `generic.linksAndWorkSheet.title.care`,
      )}
      workSheetList={SOCIAL_ACTIVITY_WORKSHEET[socialActivity]}
      linksAndWorkSheetText={
        <TranscriptionScriptPDF
          script={`sectionSeven.linksAndWorkSheet.text`}
          scriptProps={{ patientName: person.name }}
        />
      }
      links={SOCIAL_ACTIVITY_LINKS[socialActivity]}
      keepInMindList={keepInMind}
      followRecommendationsText={t(
        'sectionSeven.linksAndWorkSheet.followRecommendations.text',
      )}
      omsText={t(
        'sectionSeven.linksAndWorkSheet.followRecommendations.omsText',
      )}
      worksheetId={`worksheet-social`}
    />
  )
}
