import React from 'react'

import ResultsDescription from '../components/ResultsDescription'
import { useTranslation } from 'react-i18next'
import useFetchUserInfo from '../hooks/useFetchUserInfo'

const TobaccoAndAlcoholSR: React.FC = () => {
  const { analysisData, loading, error } = useFetchUserInfo()

  const { t } = useTranslation()

  const yAxisLabels = t('myEvolution.tobaccoAndAlcohol.chart.yAxisLabels', {
    returnObjects: true,
  }) as string[]

  return (
    <div className="flex h-full gap-6 justify-start flex-1 pt-8 pb-6 px-6  w-full">
      <div className="flex flex-col w-full max-w-[830px]">
        <ResultsDescription
          data={analysisData}
          scoreKey="FAGERSTRON"
          scoreKey2="RICHMOND"
          scoreKey3="AUDIT_C"
          description={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescription.description',
          )}
          title={t('myEvolution.tobaccoAndAlcohol.resultsDescription.title')}
          title2={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescription.richmondTitle',
          )}
          title3={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescription.auditCTitle',
          )}
          modalLink={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescription.modalLink',
          )}
          chartTitle={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescription.chartTitle',
          )}
          chartDescription={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescription.chartDescription',
          )}
          chartDescription2={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescription.richmondChartDescription',
          )}
          chartDescription3={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescription.auditCChartDescription',
          )}
          modalDescription={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescriptionModal.description',
          )}
          modalDescription2={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescriptionModal.description2',
          )}
          modalDescription3={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescriptionModal.description3',
          )}
          modalTitle={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescriptionModal.title',
          )}
          modalTitle2={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescriptionModal.richmondTitle',
          )}
          modalTitle3={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescriptionModal.auditCTitle',
          )}
          modalButton={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescriptionModal.button',
          )}
          loading={loading}
          error={error}
          loadingText={t(
            'myEvolution.tobaccoAndAlcohol.resultsDescription.loading',
          )}
          lowLabel={t('myEvolution.tobaccoAndAlcohol.chart.legend.low')}
          mediumLabel={t('myEvolution.tobaccoAndAlcohol.chart.legend.medium')}
          highLabel={t('myEvolution.tobaccoAndAlcohol.chart.legend.high')}
          yAxisLabels={yAxisLabels || []}
        />
      </div>
    </div>
  )
}

export default TobaccoAndAlcoholSR
