import React from 'react'
import { useTranslation } from 'react-i18next'
import { Information } from '@carbon/icons-react'
import { useLocation } from 'react-router-dom'

interface NoDataMessageProps {
  noGraphic?: boolean
}

const NoDataMessage: React.FC<NoDataMessageProps> = ({ noGraphic }) => {
  const { t } = useTranslation()
  const location = useLocation()

  const isSocialOrAuditory =
    location.pathname.includes('social') ||
    location.pathname.includes('auditory_ocular')

  return (
    <div
      className={`flex justify-start items-center h-auto sm:h-[88px] bg-generic-informative-50 rounded-lg p-4 text-generic-neutral-900 font-aeonik`}
    >
      <div className="h-full flex justify-center items-start sm:items-center">
        <Information className="mr-2 text-generic-informative-700 w-6 h-6" />
      </div>
      <p className="text-[20px] ml-2">
        {isSocialOrAuditory
          ? t('myEvolution.noGraphic2')
          : noGraphic
            ? t('myEvolution.noGraphic')
            : t('myEvolution.noData')}
      </p>
    </div>
  )
}

export default NoDataMessage
