import React from 'react'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
  ReferenceLine,
} from 'recharts'
import { AnalysisHistoricalResults, ScoreEntry, ScoreData } from '../../types'
import usePadding from '../../hooks/useChartBarsPositions'
import { formatDate, getBarColor } from '../../utils/chartUtils'

interface EvolutionEmotionalBarChartProps {
  data: AnalysisHistoricalResults | null
  scoreKey: string
  lowLabel?: string
  mediumLabel?: string
  highLabel?: string
  yAxisLabels: string[]
}

const EvolutionEmotionalBarChart: React.FC<EvolutionEmotionalBarChartProps> = ({
  data,
  scoreKey,
  lowLabel,
  mediumLabel,
  highLabel,
}) => {
  const padding = usePadding()

  const scoreData = data?.data?.attributes?.scores
    ?.find((s: ScoreData) => s.name === scoreKey)
    ?.data.map((item: ScoreEntry) => ({
      date: item.date,
      score: item.score,
      rating: Number(item.rating),
    }))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

  let minScore
  let maxScore

  switch (scoreKey) {
    case 'GOLDBERG_ANXIETY':
      minScore = 0
      maxScore = 4.2
      break
    case 'GOLDBERG_DEPRESSION':
      minScore = 0
      maxScore = 4.2
      break
    default:
      minScore = 0
      maxScore = 4.2
  }

  const validData = scoreData
    ? scoreData
        .slice(-3)
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    : []

  if (!validData.length) {
    console.warn(`${scoreKey} no está disponible o no tiene datos.`)
  }

  const ticks = [1, 2, 3, 4]
  const yAxisLabelsWithDescriptions = ['Baja', '', '', 'Alta']

  const yAxisLabels = yAxisLabelsWithDescriptions
  const axisLabels = ticks

  const renderCustomLegend = () => {
    return (
      <div className="mt-4">
        <ul className="flex flex-col gap-1 list-none p-0 font-aeonik text-generic-neutral-900">
          <li className="flex items-center">
            <div className="w-4 h-4 bg-error mr-4 rounded-sm" />
            {lowLabel}
          </li>
          {mediumLabel && (
            <li className="flex items-center">
              <div className="w-4 h-4 bg-warning mr-4 rounded-sm" />
              {mediumLabel}
            </li>
          )}
          <li className="flex items-center">
            <div className="w-4 h-4 bg-success mr-4 rounded-sm" />
            {highLabel}
          </li>
        </ul>
      </div>
    )
  }

  return (
    <div className="w-[100%] sm:w-[60%]">
      <ResponsiveContainer height={350}>
        <BarChart
          data={validData}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          <XAxis
            dataKey="date"
            tickFormatter={formatDate}
            scale="point"
            padding={padding}
            axisLine={true}
            tickLine={true}
          />

          <YAxis
            domain={[minScore, maxScore]}
            ticks={axisLabels}
            tickFormatter={(value) => {
              const index = axisLabels.indexOf(value)
              return yAxisLabels[index] !== undefined ? yAxisLabels[index] : ''
            }}
            interval={0}
            tickCount={axisLabels.length}
            width={100}
          />

          <ReferenceLine y={1} stroke="#0E8343" strokeDasharray="3 3" />
          <ReferenceLine y={2} stroke="#F7E06D" strokeDasharray="3 3" />
          <ReferenceLine y={3} stroke="#AD941A" strokeDasharray="3 3" />
          <ReferenceLine y={4} stroke="#A52222" strokeDasharray="3 3" />

          <Tooltip
            formatter={(value) => `${value} pts`}
            labelFormatter={formatDate}
          />
          <Legend content={renderCustomLegend} />

          <Bar
            dataKey="score"
            barSize={40}
            fillOpacity={1}
            stroke="none"
            name="Puntuación"
          >
            {validData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getBarColor(entry)} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default EvolutionEmotionalBarChart
