import { useTranslation } from 'react-i18next'
import EvolutionBarChart from './Charts/EvolutionBarChart'
import { AnalysisHistoricalResults } from '../types'

interface GenericChartsProps {
  handleModalToggle: () => void
  title: string
  modalLink: string
  chartTitle: string
  chartDescription: string
  error: unknown
  data: AnalysisHistoricalResults | null
  scoreKey: string
  lowLabel: string
  mediumLabel: string
  highLabel: string
  yAxisLabels: string[]
  noScoresInChart: boolean
}

const IPAQCharts = ({
  handleModalToggle,
  title,
  modalLink,
  chartTitle,
  chartDescription,
  error,
  data,
  scoreKey,
  lowLabel,
  mediumLabel,
  highLabel,
  yAxisLabels,
  noScoresInChart,
}: GenericChartsProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-8">
      {noScoresInChart ? null : (
        <div className="flex flex-col w-full bg-neutral-100 rounded-lg p-6 lg:p-10 min-h-[653px] font-aeonik text-generic-neutral-900">
          <h3 className="text-2xl mb-4 text-[#333333]">{title}</h3>
          <h4
            className="underline mb-4 text-xl cursor-pointer"
            onClick={handleModalToggle}
          >
            {modalLink}
          </h4>
          <h4 className="mb-4 text-2xl">{chartTitle}</h4>
          <h5 className="mb-5 text-base">{chartDescription}</h5>
          {error ? (
            <div className="flex justify-center items-center h-full text-center">
              <p>
                {t('myEvolution.resultsDescription.fetchError1')} <br />
                {t('myEvolution.resultsDescription.fetchError2')}
              </p>
            </div>
          ) : (
            <EvolutionBarChart
              data={data}
              scoreKey={scoreKey || ''}
              lowLabel={lowLabel}
              mediumLabel={mediumLabel}
              highLabel={highLabel}
              yAxisLabels={yAxisLabels || []}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default IPAQCharts
