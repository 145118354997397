import { FC } from 'react'
import { CloseLarge } from '@carbon/icons-react'

interface DescriptionModalProps {
  onClose: () => void
  description: string
  title: string
  button: string
}

export const DescriptionModal: FC<DescriptionModalProps> = ({
  onClose,
  description,
  title,
  button,
}) => {
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 font-aeonik text-generic-neutral-900"
      onClick={handleOverlayClick}
    >
      <div className="bg-white p-6 rounded-lg shadow-lg relative md:max-w-[calc(100%-450px)] md:max-h-[calc(100%-450px)] w-full h-full md:m-56 m-0 max-w-full max-h-full">
        <button
          className="absolute top-4 right-4 ml-8 mt-2"
          onClick={onClose}
          aria-label="Cerrar modal"
        >
          <CloseLarge width={24} height={24} />
        </button>
        <div className="flex flex-col justify-between h-full">
          <div>
            <h2 className="text-2xl font-bold mb-4">{title}</h2>
            <p className="text-lg">{description}</p>
          </div>
          <button
            onClick={onClose}
            className="bg-generic-primary-50 text-generic-neutral-900 p-2 rounded-md w-full h-10 border border-primary"
          >
            {button}
          </button>
        </div>
      </div>
    </div>
  )
}
