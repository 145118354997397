import { t } from 'i18next'
import { Calendar, DataCheck, Warning } from '@carbon/icons-react'

export const getHeadersCommunication = () => [
  { icon: <Calendar size={24} />, title: t('common:date') },
  { icon: <DataCheck size={24} />, title: t('common:register') },
  {
    icon: <Warning size={24} />,
    title: t('userArea.monitoringArea.headers.requiredActions'),
  },
]

const TRANSLATION_BASE_KEY = 'userArea.monitoringArea.tabHeaders'

export const TAB_LINK = Object.freeze({
  physical_activity: 'physical_activity',
  nutrition: 'nutrition',
  cognitive: 'cognitive',
  emotional: 'emotional',
  social: 'social',
  auditory_ocular: 'auditory_ocular',
  tobacco_alcohol: 'tobacco_alcohol',
})

const TAB_TRANSLATION = Object.freeze({
  physical_activity: `${TRANSLATION_BASE_KEY}.physical_activity`,
  nutrition: `${TRANSLATION_BASE_KEY}.nutrition`,
  cognitive: `${TRANSLATION_BASE_KEY}.cognitive`,
  emotional: `${TRANSLATION_BASE_KEY}.emotional`,
  social: `${TRANSLATION_BASE_KEY}.social`,
  auditory_ocular: `${TRANSLATION_BASE_KEY}.auditory_ocular`,
  tobacco_alcohol: `${TRANSLATION_BASE_KEY}.tobacco_alcohol`,
})

export const EvolutionTabs = () => [
  {
    title: t(TAB_TRANSLATION.physical_activity).toUpperCase(),
    link: TAB_LINK.physical_activity,
  },
  {
    title: t(TAB_TRANSLATION.nutrition).toUpperCase(),
    link: TAB_LINK.nutrition,
  },
  {
    title: t(TAB_TRANSLATION.cognitive).toUpperCase(),
    link: TAB_LINK.cognitive,
  },
  {
    title: t(TAB_TRANSLATION.emotional).toUpperCase(),
    link: TAB_LINK.emotional,
  },
  {
    title: t(TAB_TRANSLATION.social).toUpperCase(),
    link: TAB_LINK.social,
  },
  {
    title: t(TAB_TRANSLATION.auditory_ocular).toUpperCase(),
    link: TAB_LINK.auditory_ocular,
  },
  {
    title: t(TAB_TRANSLATION.tobacco_alcohol).toUpperCase(),
    link: TAB_LINK.tobacco_alcohol,
  },
]
