import React from 'react'
import useFetchUserInfo from '../hooks/useFetchUserInfo'
import ResultsDescription from '../components/ResultsDescription'
import { useTranslation } from 'react-i18next'

const NutritionalSR: React.FC = () => {
  const { analysisData, loading, error } = useFetchUserInfo()

  const { t } = useTranslation()
  const yAxisLabels = t('myEvolution.nutritional.chart.yAxisLabels', {
    returnObjects: true,
  }) as string[]

  return (
    <div className="flex h-full gap-6 justify-start flex-1 pt-8 pb-6 px-6  w-full">
      <div className="flex flex-col w-full max-w-[830px]">
        <ResultsDescription
          data={analysisData}
          scoreKey="MEDAS"
          description={t(
            'myEvolution.nutritional.resultsDescription.description',
          )}
          title={t('myEvolution.nutritional.resultsDescription.title')}
          modalLink={t('myEvolution.nutritional.resultsDescription.modalLink')}
          chartTitle={t(
            'myEvolution.nutritional.resultsDescription.chartTitle',
          )}
          title2={t('myEvolution.nutritional.resultsDescription.imcTitle')}
          chartDescription={t(
            'myEvolution.nutritional.resultsDescription.chartDescription',
          )}
          imcChartDescription={t(
            'myEvolution.nutritional.resultsDescription.imcChartDescription',
          )}
          modalDescription={t(
            'myEvolution.nutritional.resultsDescriptionModal.description',
          )}
          modalDescription2={t(
            'myEvolution.nutritional.resultsDescriptionModal2.description',
          )}
          modalTitle={t(
            'myEvolution.nutritional.resultsDescriptionModal.title',
          )}
          modalTitle2={t(
            'myEvolution.nutritional.resultsDescriptionModal2.title',
          )}
          modalButton={t(
            'myEvolution.nutritional.resultsDescriptionModal.button',
          )}
          modalButton2={t(
            'myEvolution.nutritional.resultsDescriptionModal2.button',
          )}
          loading={loading}
          error={error}
          loadingText={t('myEvolution.nutritional.resultsDescription.loading')}
          lowLabel={t('myEvolution.nutritional.chart.legend.low')}
          mediumLabel={t('myEvolution.nutritional.chart.legend.medium')}
          highLabel={t('myEvolution.nutritional.chart.legend.high')}
          imcLegendLow={t('myEvolution.nutritional.chart.imcChart.legend.low')}
          imcLegendMedium={t(
            'myEvolution.nutritional.chart.imcChart.legend.medium',
          )}
          imcLegendHigh={t(
            'myEvolution.nutritional.chart.imcChart.legend.high',
          )}
          yAxisLabels={yAxisLabels || []}
        />
      </div>
    </div>
  )
}

export default NutritionalSR
