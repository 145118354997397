import { areaColorKey } from '../../utils'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { FollowRecomendations } from '../../components/FollowRecomendations'
import { useReportPdfContext } from '../../report-pdf-provider'
import { useMemo } from 'react'

import {
  NUTRITIONAL_KEYS,
  NUTRITIONAL_ACTIVITY_LINKS,
  NUTRITIONAL_ACTIVITY_WORKSHEET,
} from './enum'

export const SecondPage = () => {
  const { t, data, person, type } = useReportPdfContext()

  const areaColor = areaColorKey.green

  const keepInMind = [
    <TranscriptionScriptPDF script="initial-report:sectionFour.linksAndWorkSheet.keepInMind.one" />,
    <TranscriptionScriptPDF script="initial-report:sectionFour.linksAndWorkSheet.keepInMind.two" />,
  ]

  const { recommendations } = data?.results?.initialAnalysisList || {}

  const getNutritional = () => {
    let activityIncluded = ''

    Object.values(NUTRITIONAL_KEYS).forEach((activity) => {
      if (recommendations.NUTRITIONAL.includes(activity)) {
        activityIncluded = activity
      }
    })

    return activityIncluded
  }

  const nutritionalActivity = useMemo(() => {
    return getNutritional()
  }, [recommendations])

  return (
    <FollowRecomendations
      translateFrom={'sectionFour.linksAndWorkSheet'}
      areaColor={areaColor}
      linksAndWorkSheetTitle={t(
        `generic.linksAndWorkSheet.title.care`,
      )}
      linksAndWorkSheetText={
        <TranscriptionScriptPDF
          script={`sectionFour.linksAndWorkSheet.text`}
          scriptProps={{ patientName: person.name }}
        />
      }
      links={NUTRITIONAL_ACTIVITY_LINKS[nutritionalActivity]}
      keepInMindList={keepInMind}
      followRecommendationsText={t(
        'sectionFour.linksAndWorkSheet.followRecommendations.text',
      )}
      omsText={t(
        'sectionFour.linksAndWorkSheet.followRecommendations.omsText',
      )}
      workSheetList={NUTRITIONAL_ACTIVITY_WORKSHEET[nutritionalActivity]}
      worksheetId={`worksheet-nutritional`}
    />
  )
}


//