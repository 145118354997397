import i18next, { t } from 'i18next';

export const yAxisTickFormatter = (value: number) => {
  switch (value) {
    case 0:
      return i18next.t('myEvolution.chart.yAxis.low'); 
    case 1:
      return i18next.t('myEvolution.chart.yAxis.moderate'); 
    case 2:
      return i18next.t('myEvolution.chart.yAxis.high'); 
    default:
      return value.toString();
  }
}

export type Entry = {
  rating: number;
};

export const getBarColor = (entry: Entry) => {
  switch (entry.rating) {
    case 2:
      return '#226F54'
    case 1:
      return '#F7E06D'
    case 0:
      return '#B3261E'
    default:
      return '#004039'
  }
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  return `${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`
}

export const customTickFormatter = (value: number, maxScore: number) => {
    if (value === 0) {
      return t('myEvolution.chart.yAxis.low')
    }
    const half = maxScore / 2
    if (Math.abs(value - half) < 0.000001) {
      return t('myEvolution.chart.yAxis.moderate')
    }
    if (Math.abs(value - maxScore) < 0.000001) {
      return t('myEvolution.chart.yAxis.high')
    }
    return ''
  }