import { t } from 'i18next'

const TRANSLATION_BASE_KEY = 'userArea.monitoringArea.tabHeaders'

export const TAB_LINK = Object.freeze({
  communications: 'communications',
  register: 'register',
  chat: 'chat',
  agenda: 'agenda',
  services: 'services',
  evolution: 'evolution',
})

const TAB_TRANSLATION = Object.freeze({
  communications: `${TRANSLATION_BASE_KEY}.communications`,
  register: `${TRANSLATION_BASE_KEY}.register`,
  chat: `${TRANSLATION_BASE_KEY}.chat`,
  agenda: `${TRANSLATION_BASE_KEY}.agenda`,
  services: `${TRANSLATION_BASE_KEY}.services`,
  evolution: `${TRANSLATION_BASE_KEY}.evolution`,
})

export const PreCSTabs = () => [
  {
    title: t(TAB_TRANSLATION.communications).toUpperCase(),
    link: TAB_LINK.communications,
  },
  {
    title: t(TAB_TRANSLATION.register).toUpperCase(),
    link: TAB_LINK.register,
  },
  {
    title: t(TAB_TRANSLATION.evolution).toUpperCase(),
    link: TAB_LINK.evolution,
  },
]

export const PostCsTabs = () => [
  {
    title: t(TAB_TRANSLATION.chat).toUpperCase(),
    link: TAB_LINK.chat,
  },
  {
    title: t(TAB_TRANSLATION.agenda).toUpperCase(),
    link: TAB_LINK.agenda,
  },
  {
    title: t(TAB_TRANSLATION.services).toUpperCase(),
    link: TAB_LINK.services,
  },
  
  ...PreCSTabs(),
]
