import { View } from '@react-pdf/renderer'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { tw } from '../../utils'
import { TickList } from '../../components/TickList'
import { ListItem } from '../../components/ListItem'

export const AUDITORY_KEYS = Object.freeze({
  recAuditoryOcularAuditoryNoIssues: 'rec_auditory_ocular_auditory_no_issues',
  recAuditoryOcularReferPrimaryCareAuditory:
    'rec_auditory_ocular_refer_primary_care_auditory',
  recAuditoryOcularPriorDiagnosisAuditory:
    'rec_auditory_ocular_prior_diagnosis_auditory',
  recAuditoryOcularHearingAid: 'rec_auditory_ocular_hearing_aid',
  recAuditoryOcularNoHearingAid: 'rec_auditory_ocular_no_hearing_aid',
  recAuditoryOcularHearingImplant: 'rec_auditory_ocular_hearing_implant',
})

export const OCULAR_KEYS = Object.freeze({
  recAuditoryOcularOphthalmological: 'rec_auditory_ocular_ophthalmological',
  recAuditoryOcularPrevHypertensiveRetinopathy:
    'rec_auditory_ocular_prev_hypertensive_retinopathy',
  recAuditoryOcularHypertensiveRetinopathy:
    'rec_auditory_ocular_hypertensive_retinopathy',
  recAuditoryOcularPrevHypertensiveGlaucoma:
    'rec_auditory_ocular_prev_hypertensive_glaucoma',
  recAuditoryOcularHypertensiveGlaucoma:
    'rec_auditory_ocular_hypertensive_glaucoma',
  recAuditoryOcularPrevDiabeticRetinopathy:
    'rec_auditory_ocular_prev_diabetic_retinopathy',
  recAuditoryOcularDiabeticRetinopathy:
    'rec_auditory_ocular_diabetic_retinopathy',
  recAuditoryOcularDiabetesGlaucoma: 'rec_auditory_ocular_diabetes_glaucoma',
  recAuditoryOcularPriorDiagnosisVisionNo:
    'rec_auditory_ocular_prior_diagnosis_vision_no',
  recAuditoryOcularPriorDiagnosisVision:
    'rec_auditory_ocular_prior_diagnosis_vision',
  recAuditoryOcularPrevDiabetesAndHypertension:
    'rec_auditory_ocular_prev_diabetes_and_hypertension',
  recAuditoryOcularGenericVision: 'rec_auditory_ocular_generic_vision',
  recAuditoryOcularPrevAterialHypertension:
    'rec_auditory_ocular_prev_arterial_hypertension',
})

export const getKey = (SOCIAL) => {
  if (!SOCIAL) return false
  return SOCIAL.find((key) => {
    if (Object.values(AUDITORY_KEYS).includes(key)) return key

    return null
  })
}
export const getOcularKey = (OCULAR) => {
  if (!OCULAR || OCULAR.length === 0)
    return 'rec_auditory_ocular_generic_vision'

  const foundKeys = OCULAR.filter((key) =>
    Object.values(OCULAR_KEYS).includes(key),
  )

  if (foundKeys.length > 1) {
    return 'rec_auditory_ocular_generic_vision'
  }

  return foundKeys[0] || 'rec_auditory_ocular_generic_vision'
}

const auditiveTickList = () => [
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.auditory.one.tickList.one'
    }
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.auditory.one.tickList.two'
    }
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.auditory.one.tickList.three'
    }
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.auditory.one.tickList.four'
    }
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.auditory.one.tickList.five'
    }
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.auditory.one.tickList.six'
    }
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.auditory.one.tickList.seven'
    }
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.auditory.one.tickList.eight'
    }
  />,
]

const ocularTickList = () => [
  <TranscriptionScriptPDF
    script={'initial-report:sectionEight.keyGuidelines.ocular.one.tickList.one'}
  />,
  <TranscriptionScriptPDF
    script={'initial-report:sectionEight.keyGuidelines.ocular.one.tickList.two'}
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.ocular.one.tickList.three'
    }
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.ocular.one.tickList.four'
    }
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.ocular.one.tickList.five'
    }
  />,
  <TranscriptionScriptPDF
    script={'initial-report:sectionEight.keyGuidelines.ocular.one.tickList.six'}
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.ocular.one.tickList.seven'
    }
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.ocular.one.tickList.eight'
    }
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.ocular.one.tickList.nine'
    }
  />,
  <TranscriptionScriptPDF
    script={'initial-report:sectionEight.keyGuidelines.ocular.one.tickList.ten'}
  />,
  <TranscriptionScriptPDF
    script={
      'initial-report:sectionEight.keyGuidelines.ocular.one.tickList.eleven'
    }
  />,
]

export const AUDITORY_KEY_GUIDELINES = (type) =>
  Object.freeze({
    [AUDITORY_KEYS.recAuditoryOcularAuditoryNoIssues]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.one.title'
            }
          />
        ),

        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.auditory.one.text'
              }
            />
            <TickList list={auditiveTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_auditory_no_issues.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_auditory_no_issues.one.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_auditory_no_issues.two.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={`initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_auditory_no_issues.two.text.${type}`}
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_auditory_no_issues.three.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_auditory_no_issues.three.text'
            }
          />
        ),
      },
    ],
    [AUDITORY_KEYS.recAuditoryOcularReferPrimaryCareAuditory]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_refer_primary_care_auditory.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_refer_primary_care_auditory.one.content'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_refer_primary_care_auditory.two.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_refer_primary_care_auditory.two.content'
            }
          />
        ),
      },
    ],
    [AUDITORY_KEYS.recAuditoryOcularPriorDiagnosisAuditory]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.auditory.one.text'
              }
            />
            <TickList list={auditiveTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_prior_diagnosis_auditory.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_prior_diagnosis_auditory.one.text'
            }
          />
        ),
      },
    ],
    [AUDITORY_KEYS.recAuditoryOcularHearingAid]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.auditory.one.text'
              }
            />
            <TickList list={auditiveTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_aid.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_aid.one.text'
              }
            />
            <TickList
              list={[
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_aid.one.tickList.one'
                  }
                />,
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_aid.one.tickList.two'
                  }
                />,
              ]}
            />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_aid.two.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_aid.two.text'
            }
          />
        ),
      },
    ],
    [AUDITORY_KEYS.recAuditoryOcularNoHearingAid]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.auditory.one.text'
              }
            />
            <TickList list={auditiveTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_no_hearing_aid.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_no_hearing_aid.one.text'
            }
          />
        ),
      },
    ],
    [AUDITORY_KEYS.recAuditoryOcularHearingImplant]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_implant.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_implant.one.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_implant.two.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_implant.two.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_implant.three.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_hearing_implant.three.text'
            }
          />
        ),
      },
    ],
  })

const OCULAR_ADDITIONAL_TIPS = () =>
  Object.freeze({
    [OCULAR_KEYS.recAuditoryOcularGenericVision]: [
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.two'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.three'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.four'
        }
      />,
    ],
    [OCULAR_KEYS.recAuditoryOcularOphthalmological]: [
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.one'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.two'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.three'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.four'
        }
      />,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevHypertensiveRetinopathy]: [
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.one'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.two'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.three'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.four'
        }
      />,
    ],
    [OCULAR_KEYS.recAuditoryOcularHypertensiveRetinopathy]: [
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.one'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.two'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.three'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.four'
        }
      />,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevHypertensiveGlaucoma]: [
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.one'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.two'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.three'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.four'
        }
      />,
    ],
    [OCULAR_KEYS.recAuditoryOcularHypertensiveGlaucoma]: [
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.one'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.two'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.three'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.four'
        }
      />,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevDiabeticRetinopathy]: [
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.five'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.two'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.three'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.four'
        }
      />,
    ],
    [OCULAR_KEYS.recAuditoryOcularDiabeticRetinopathy]: [
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.six'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.five'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.seven'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.three'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.four'
        }
      />,
    ],
    [OCULAR_KEYS.recAuditoryOcularDiabetesGlaucoma]: [
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.six'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.five'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.eight'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.seven'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.three'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.four'
        }
      />,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevDiabetesAndHypertension]: [
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.one'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.five'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.two'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.three'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.four'
        }
      />,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevAterialHypertension]: [
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.one'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.two'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.three'
        }
      />,
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.list.four'
        }
      />,
    ],
  })

export const OCULAR_KEYS_GUIDELINES = () =>
  Object.freeze({
    [OCULAR_KEYS.recAuditoryOcularGenericVision]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_generic_vision.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_generic_vision.one.text'
              }
            />
            <ListItem
              title={
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.title'
                  }
                />
              }
              list={
                OCULAR_ADDITIONAL_TIPS()[
                  OCULAR_KEYS.recAuditoryOcularGenericVision
                ]
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularOphthalmological]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_ophthalmological.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_ophthalmological.one.text'
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevHypertensiveRetinopathy]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prev_hypertensive_retinopathy.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prev_hypertensive_retinopathy.one.text'
              }
            />
            <ListItem
              title={
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.title'
                  }
                />
              }
              list={
                OCULAR_ADDITIONAL_TIPS()[
                  OCULAR_KEYS.recAuditoryOcularPrevHypertensiveRetinopathy
                ]
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularHypertensiveRetinopathy]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_hypertensive_retinopathy.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_hypertensive_retinopathy.one.text'
              }
            />
            <ListItem
              title={
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.title'
                  }
                />
              }
              list={
                OCULAR_ADDITIONAL_TIPS()[
                  OCULAR_KEYS.recAuditoryOcularHypertensiveRetinopathy
                ]
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevHypertensiveGlaucoma]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prev_hypertensive_glaucoma.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prev_hypertensive_glaucoma.one.text'
              }
            />
            <ListItem
              title={
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.title'
                  }
                />
              }
              list={
                OCULAR_ADDITIONAL_TIPS()[
                  OCULAR_KEYS.recAuditoryOcularPrevHypertensiveGlaucoma
                ]
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularHypertensiveGlaucoma]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_hypertensive_glaucoma.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_hypertensive_glaucoma.one.text'
              }
            />
            <ListItem
              title={
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.title'
                  }
                />
              }
              list={
                OCULAR_ADDITIONAL_TIPS()[
                  OCULAR_KEYS.recAuditoryOcularHypertensiveGlaucoma
                ]
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevDiabeticRetinopathy]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prev_diabetic_retinopathy.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prev_diabetic_retinopathy.one.text'
              }
            />
            <ListItem
              title={
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.title'
                  }
                />
              }
              list={
                OCULAR_ADDITIONAL_TIPS()[
                  OCULAR_KEYS.recAuditoryOcularPrevDiabeticRetinopathy
                ]
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularDiabeticRetinopathy]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_diabetic_retinopathy.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_diabetic_retinopathy.one.text'
              }
            />
            <ListItem
              title={
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.title'
                  }
                />
              }
              list={
                OCULAR_ADDITIONAL_TIPS()[
                  OCULAR_KEYS.recAuditoryOcularDiabeticRetinopathy
                ]
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularPriorDiagnosisVisionNo]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prior_diagnosis_vision_no.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prior_diagnosis_vision_no.one.text'
              }
            />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prior_diagnosis_vision_no.two.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prior_diagnosis_vision_no.two.text'
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularPriorDiagnosisVision]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prior_diagnosis_vision.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prior_diagnosis_vision.one.text'
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularDiabetesGlaucoma]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_diabetes_glaucoma.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_diabetes_glaucoma.one.text'
              }
            />
            <ListItem
              title={
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.title'
                  }
                />
              }
              list={
                OCULAR_ADDITIONAL_TIPS()[
                  OCULAR_KEYS.recAuditoryOcularDiabetesGlaucoma
                ]
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevDiabetesAndHypertension]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prev_diabetes_and_hypertension.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prev_diabetes_and_hypertension.one.text'
              }
            />
            <ListItem
              title={
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.title'
                  }
                />
              }
              list={
                OCULAR_ADDITIONAL_TIPS()[
                  OCULAR_KEYS.recAuditoryOcularPrevDiabetesAndHypertension
                ]
              }
            />
          </View>
        ),
      },
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevAterialHypertension]: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.one.text'
              }
            />
            <TickList list={ocularTickList()} />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prev_arterial_hypertension.one.title'
            }
          />
        ),
        content: (
          <View style={tw('flex flex-col gap-6')}>
            <TranscriptionScriptPDF
              script={
                'initial-report:sectionEight.keyGuidelines.ocular.rec_auditory_ocular_prev_arterial_hypertension.one.text'
              }
            />
            <ListItem
              title={
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.ocular.additionalTips.title'
                  }
                />
              }
              list={
                OCULAR_ADDITIONAL_TIPS()[
                  OCULAR_KEYS.recAuditoryOcularPrevAterialHypertension
                ]
              }
            />
          </View>
        ),
      },
    ],
  })

  export const linksAuditoryAndOcular = ({ auditoryKey, ocularKey }) => {
    const auditory = AUDITORY_OCULAR_LINKS[auditoryKey]?.length
      ? AUDITORY_OCULAR_LINKS[auditoryKey]
      : []
  
    const ocular = AUDITORY_OCULAR_LINKS[ocularKey]?.length
      ? AUDITORY_OCULAR_LINKS[ocularKey]
      : []
  
    return [{ auditory: auditory }, { ocular: ocular }]
  }

  export const worksheetsAuditoryAndOcular = ({ auditoryKey, ocularKey }) => {
    const auditory = AUDITORY_OCULAR_WORKSHEET_LINKS[auditoryKey]?.length
      ? AUDITORY_OCULAR_WORKSHEET_LINKS[auditoryKey]
      : []
  
    const ocular = AUDITORY_OCULAR_WORKSHEET_LINKS[ocularKey]?.length
      ? AUDITORY_OCULAR_WORKSHEET_LINKS[ocularKey]
      : []
  
    return [{ auditory: auditory, ocular: ocular }]
  }
  
  const Links = Object.freeze({
    auditoryOcularLinkOne: 0,
    auditoryOcularLinkTwo: 1,
    auditoryOcularLinkThree: 2,
    auditoryOcularLinkFour: 3,
    auditoryOcularLinkFive: 4,
    auditoryOcularLinkSix: 5,
    auditoryOcularLinkSeven: 6,
    auditoryOcularLinkEight: 7,
    auditoryOcularLinkNine: 8,
  })

  export const AUDITORY_OCULAR_LINKS = Object.freeze({
    [AUDITORY_KEYS.recAuditoryOcularAuditoryNoIssues]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
      Links.auditoryOcularLinkThree,
      Links.auditoryOcularLinkFour,
    ],
    [AUDITORY_KEYS.recAuditoryOcularReferPrimaryCareAuditory]: [
      Links.auditoryOcularLinkFive,
      Links.auditoryOcularLinkSix,
      Links.auditoryOcularLinkSeven,
    ],
    [AUDITORY_KEYS.recAuditoryOcularPriorDiagnosisAuditory]: [
      Links.auditoryOcularLinkFive,
      Links.auditoryOcularLinkSix,
      Links.auditoryOcularLinkSeven,
    ],
    [AUDITORY_KEYS.recAuditoryOcularHearingAid]: [
      Links.auditoryOcularLinkEight,
      Links.auditoryOcularLinkSeven,
    ],
    [AUDITORY_KEYS.recAuditoryOcularNoHearingAid]: [
      Links.auditoryOcularLinkFive,
      Links.auditoryOcularLinkSix,
    ],
    [AUDITORY_KEYS.recAuditoryOcularHearingImplant]: [
      Links.auditoryOcularLinkEight,
      Links.auditoryOcularLinkSeven,
      Links.auditoryOcularLinkSix,
    ],
    [OCULAR_KEYS.recAuditoryOcularOphthalmological]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,  
    ],
    [OCULAR_KEYS.recAuditoryOcularGenericVision]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
      Links.auditoryOcularLinkThree,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevHypertensiveRetinopathy]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo, 
    ],
    [OCULAR_KEYS.recAuditoryOcularHypertensiveRetinopathy]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo, 
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevHypertensiveGlaucoma]: [
      Links.auditoryOcularLinkFour,
      Links.auditoryOcularLinkFive,
      Links.auditoryOcularLinkThree,
    ],
    [OCULAR_KEYS.recAuditoryOcularHypertensiveGlaucoma]: [
      Links.auditoryOcularLinkFive,
      Links.auditoryOcularLinkThree,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevDiabeticRetinopathy]: [
      Links.auditoryOcularLinkFour,
      Links.auditoryOcularLinkThree,
    ],
    [OCULAR_KEYS.recAuditoryOcularDiabeticRetinopathy]: [
      Links.auditoryOcularLinkFour,
      Links.auditoryOcularLinkFive,
      Links.auditoryOcularLinkThree,
    ],
    [OCULAR_KEYS.recAuditoryOcularDiabetesGlaucoma]: [
      Links.auditoryOcularLinkFour,
      Links.auditoryOcularLinkFive,
      Links.auditoryOcularLinkThree,
    ],
    [OCULAR_KEYS.recAuditoryOcularPriorDiagnosisVisionNo]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularPriorDiagnosisVision]: [
      Links.auditoryOcularLinkThree,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevDiabetesAndHypertension]: [
      Links.auditoryOcularLinkTwo,
      Links.auditoryOcularLinkFive,
      Links.auditoryOcularLinkOne,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevAterialHypertension]: [
      Links.auditoryOcularLinkFive,
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
  })

  export const AUDITORY_OCULAR_WORKSHEET_LINKS = Object.freeze({
    [AUDITORY_KEYS.recAuditoryOcularAuditoryNoIssues]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [AUDITORY_KEYS.recAuditoryOcularReferPrimaryCareAuditory]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [AUDITORY_KEYS.recAuditoryOcularPriorDiagnosisAuditory]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkThree,
    ],
    [AUDITORY_KEYS.recAuditoryOcularHearingAid]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkThree,
    ],
    [AUDITORY_KEYS.recAuditoryOcularNoHearingAid]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkThree,
    ],
    [AUDITORY_KEYS.recAuditoryOcularHearingImplant]: [
      Links.auditoryOcularLinkThree,
    ],
    [OCULAR_KEYS.recAuditoryOcularOphthalmological]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularGenericVision]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevHypertensiveRetinopathy]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularHypertensiveRetinopathy]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevHypertensiveGlaucoma]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularHypertensiveGlaucoma]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevDiabeticRetinopathy]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularDiabeticRetinopathy]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularDiabetesGlaucoma]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularPriorDiagnosisVisionNo]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularPriorDiagnosisVision]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevDiabetesAndHypertension]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
    [OCULAR_KEYS.recAuditoryOcularPrevAterialHypertension]: [
      Links.auditoryOcularLinkOne,
      Links.auditoryOcularLinkTwo,
    ],
  })
  