import { ROUTE_PATH } from './enums'
import {
  InitialReport,
  PreSubscriptionControl,
  SubscriptionControl,
  MyProfile,
  UserList,
  MonitoringArea,
  Schedule,
} from '@sr/routes/index'

import { PreInitialReport } from '@sr/routes/initial-report/pre-initial-report'
import { Messages } from '@sr/routes/monitoring-area/messages'
import { Communications } from '@sr/routes/monitoring-area/communications'
import { Register } from '@sr/routes/monitoring-area/register'
import { MonitoringAgenda } from '@sr/routes/monitoring-area/agenda'
import { Services } from '@sr/routes/monitoring-area/services'
import { ServiceDetail } from '@sr/routes/monitoring-area/services/detail'
import { Evolution } from '@sr/routes/monitoring-area/evolution'
import { PhysicalActivity } from '@sr/routes/monitoring-area/evolution/charts/physical-activity'
import {
  CareAnalysis,
  CareAppointmentManager,
  CareUserProfile,
  TestListCare,
  UserAreaCare,
} from '@sr/routes/care/user-area'
import { TestScript } from '@sr/routes/common'
import { Social } from '@sr/routes/monitoring-area/evolution/charts/social'
import { Emotional } from '@sr/routes/monitoring-area/evolution/charts/emotional'
import { Cognitive } from '@sr/routes/monitoring-area/evolution/charts/cognitive'
import { AuditoryOcular } from '@sr/routes/monitoring-area/evolution/charts/auditory-ocular'
import { TobaccoAlcohol } from '@sr/routes/monitoring-area/evolution/charts/tobacco-alcohol'
import { Nutrition } from '@sr/routes/monitoring-area/evolution/charts/nutrition'

export const myBoxCareRoutes = (userAreaRoutes = []) => [
  {
    path: ROUTE_PATH.userList,
    element: <UserList />,
  },
  {
    path: ROUTE_PATH.srProfile(':id'),
    element: <MyProfile />,
  },
  {
    path: ROUTE_PATH.userArea(':id'),
    children: [
      {
        index: true,
        element: <UserAreaCare />,
      },
      {
        path: ROUTE_PATH.preSubscriptionControl,
        element: <PreSubscriptionControl />,
      },
      {
        path: ROUTE_PATH.subscriptionControl,
        element: <SubscriptionControl />,
      },
      {
        path: ROUTE_PATH.userProfile,
        element: <CareUserProfile />,
      },
      {
        path: ROUTE_PATH.monitoringArea,
        element: <MonitoringArea />,
        children: [
          {
            path: ROUTE_PATH.monitoringChat,
            element: <Messages />,
          },
          {
            path: ROUTE_PATH.monitoringAgenda,
            element: <MonitoringAgenda />,
          },
          {
            path: ROUTE_PATH.monitoringRegister,
            element: <Register />,
          },
          {
            path: ROUTE_PATH.monitoringCommunications,
            element: <Communications />,
          },
          {
            path: ROUTE_PATH.monitoringServices,
            element: <Services />,
          },
          {
            path: ROUTE_PATH.monitoringEvolution,
            element: <Evolution />,
            children: [
              {
                path: ROUTE_PATH.monitoringEvolutionPhysicalActivity,
                element: <PhysicalActivity />,
              },
              {
                path: ROUTE_PATH.monitoringEvolutionNutrition,
                element: <Nutrition />,
              },
              {
                path: ROUTE_PATH.monitoringEvolutionSocial,
                element: <Social />,
              },
              {
                path: ROUTE_PATH.monitoringEvolutionEmotional,
                element: <Emotional />,
              },
              {
                path: ROUTE_PATH.monitoringEvolutionCognitive,
                element: <Cognitive />,
              },
              {
                path: ROUTE_PATH.monitoringEvolutionAuditoryOcular,
                element: <AuditoryOcular />,
              },
              {
                path: ROUTE_PATH.monitoringEvolutionTobaccoAlcohol,
                element: <TobaccoAlcohol />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTE_PATH.monitoringServicesDetail,
        element: <ServiceDetail />,
      },
      {
        path: ROUTE_PATH.preInitialReport,
        element: <PreInitialReport />,
      },
      {
        path: ROUTE_PATH.initialReport,
        element: <InitialReport />,
      },
      {
        path: ROUTE_PATH.scriptTest(':testId'),
        element: <TestScript userAreaUrl="user-area" />,
      },
      {
        path: ROUTE_PATH.analysis(':testId'),
        element: <CareAnalysis />,
      },
      {
        path: ROUTE_PATH.newTest,
        element: <TestListCare />,
      },
      {
        path: ROUTE_PATH.schedule,
        element: <Schedule />,
      },
      {
        path: ROUTE_PATH.appointmentManager,
        element: <CareAppointmentManager />,
      },
      ...userAreaRoutes,
    ],
  },
]
