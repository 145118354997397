import React from 'react'
import ResultsDescription from '../components/ResultsDescription'
import { useTranslation } from 'react-i18next'
import useFetchUserInfo from '../hooks/useFetchUserInfo'

const AuditoryAndOcularSR: React.FC = () => {
  const { loading, error } = useFetchUserInfo()

  const { t } = useTranslation()

  console.log(t('myEvolution.noGraphic2'))

  return (
    <div className="flex h-full gap-6 justify-start flex-1 pt-8 pb-6 px-6  w-full">
      <div className="flex flex-col w-full max-w-[830px]">
        <ResultsDescription
          noGraphic={t('myEvolution.noGraphic2')}
          loading={loading}
          loadingText={t(
            'myEvolution.auditoryAndOcular.resultsDescription.loading',
          )}
          error={error}
        />
      </div>
    </div>
  )
}

export default AuditoryAndOcularSR
