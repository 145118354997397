import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'

export const COGNITIVE_EMOTIONAL_WELLBEING = Object.freeze({
  recCognitiveAnxiety: 'rec_emotional_anxiety',
  recCognitiveEmotionalWellbeing: 'rec_emotional_wellbeing',
  recCognitiveEmotionalWellbeingNo: 'rec_emotional_wellbeing_no',
  recCognitiveDepression: 'rec_emotional_depression',
  recEmotionalMentalIllness: 'rec_emotional_mental_illness',
})

export const COGNITIVE_SLEEP = Object.freeze({
  recCognitiveSleepIssuesNo: 'rec_emotional_sleep_issues_no',
  recCognitiveSleepIssues: 'rec_emotional_sleep_issues',
})

export const COGNITIVE_EMOTIONAL_WELLBEING_TRANSCRIPTION_MESSAGE = (
  patientName
) =>
  Object.freeze({
    [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveAnxiety]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.assesmentScale.rec_emotional_anxiety'
        }
        scriptProps={{ patientName }}
      />
    ),
    [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveEmotionalWellbeing]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.assesmentScale.rec_emotional_wellbeing'
        }
        scriptProps={{ patientName }}
      />
    ),
    [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveEmotionalWellbeingNo]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.assesmentScale.rec_emotional_wellbeing_no'
        }
        scriptProps={{ patientName }}
      />
    ),
    [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveDepression]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.assesmentScale.rec_emotional_depression'
        }
        scriptProps={{ patientName }}
      />
    ),
    [COGNITIVE_EMOTIONAL_WELLBEING.recEmotionalMentalIllness]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.assesmentScale.rec_emotional_mental_illness'
        }
        scriptProps={{ patientName }}
      />
    ),
  })

export const COGNITIVE_SLEEP_TRANSCRIPTION_MESSAGE = (patientName) =>
  Object.freeze({
    [COGNITIVE_SLEEP.recCognitiveSleepIssuesNo]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.assesmentScale.rec_emotional_sleep_issues_no'
        }
        scriptProps={{ patientName }}
      />
    ),
    [COGNITIVE_SLEEP.recCognitiveSleepIssues]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.assesmentScale.rec_emotional_sleep_issues'
        }
        scriptProps={{ patientName }}
      />
    ),
  })

export const COGNITIVE_EMOTIONAL_WELLBEING_OBJETIVE_TRANSCRIPTION_MESSAGE =
  Object.freeze({
    [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveAnxiety]: (
      <TranscriptionScriptPDF
        script={'initial-report:sectionSix.objetives.rec_emotional_anxiety'}
      />
    ),
    [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveEmotionalWellbeing]: (
      <TranscriptionScriptPDF
        script={'initial-report:sectionSix.objetives.rec_emotional_wellbeing'}
      />
    ),
    [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveEmotionalWellbeingNo]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.objetives.rec_emotional_wellbeing_no'
        }
      />
    ),
    [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveDepression]: (
      <TranscriptionScriptPDF
        script={'initial-report:sectionSix.objetives.rec_emotional_depression'}
      />
    ),
    [COGNITIVE_EMOTIONAL_WELLBEING.recEmotionalMentalIllness]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.objetives.rec_emotional_mental_illness'
        }
      />
    ),
  })

export const COGNITIVE_SLEEP_OBJETIVE_TRANSCRIPTION_MESSAGE = Object.freeze({
  [COGNITIVE_SLEEP.recCognitiveSleepIssuesNo]: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionSix.objetives.rec_emotional_sleep_issues_no'
      }
    />
  ),
  [COGNITIVE_SLEEP.recCognitiveSleepIssues]: (
    <TranscriptionScriptPDF
      script={'initial-report:sectionSix.objetives.rec_emotional_sleep_issues'}
    />
  ),
})

export const COGNITIVE_EMOTIONAL_WELLBEING_TIPS_TRANSCRIPTION = Object.freeze({
  [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveAnxiety]: {
    description: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.description'
        }
      />
    ),
    list: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.one.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.two.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.two.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.three.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.three.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.four.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.four.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.five.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.five.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.six.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.six.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.seven.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_anxiety.seven.text'
            }
          />
        ),
      },
    ],
  },
  [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveEmotionalWellbeing]: {
    description: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.description'
        }
      />
    ),
    list: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.one.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.two.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.two.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.three.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.three.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.four.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.four.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.five.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.five.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.six.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing.six.text'
            }
          />
        ),
      },
    ],
  },
  [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveEmotionalWellbeingNo]: {
    description: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.description'
        }
      />
    ),
    list: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.one.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.two.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.two.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.three.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.three.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.four.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.four.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.five.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.five.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.six.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.six.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.seven.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_wellbeing_no.seven.text'
            }
          />
        ),
      },
    ],
  },
  [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveDepression]: {
    description: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.description'
        }
      />
    ),
    list: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.one.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.two.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.two.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.three.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.three.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.four.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.four.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.five.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.five.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.six.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.six.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.seven.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_depression.seven.text'
            }
          />
        ),
      },
    ],
  },
  [COGNITIVE_EMOTIONAL_WELLBEING.recEmotionalMentalIllness]: {
    description: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.description'
        }
      />
    ),
    list: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.one.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.two.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.two.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.three.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.three.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.four.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.four.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.five.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.five.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.six.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.six.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.seven.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_mental_illness.seven.text'
            }
          />
        ),
      },
    ],
  },
})

export const COGNITIVE_SLEEP_TIPS_TRANSCRIPTION = Object.freeze({
  [COGNITIVE_SLEEP.recCognitiveSleepIssuesNo]: {
    description: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.description'
        }
      />
    ),
    list: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.one.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.two.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.two.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.three.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.three.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.four.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.four.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.five.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.five.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.six.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues_no.six.text'
            }
          />
        ),
      },
    ],
  },
  [COGNITIVE_SLEEP.recCognitiveSleepIssues]: {
    description: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.description'
        }
      />
    ),
    list: [
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.one.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.one.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.two.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.two.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.three.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.three.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.four.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.four.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.five.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.five.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.six.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.six.text'
            }
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.seven.title'
            }
          />
        ),
        content: (
          <TranscriptionScriptPDF
            script={
              'initial-report:sectionSix.keyGuidelines.tipList.rec_emotional_sleep_issues.seven.text'
            }
          />
        ),
      },
    ],
  },
})

const Links = {
  emotionalLinkOne: 0,
  emotionalLinkTwo: 1,
  emotionalLinkThree: 2,
  emotionalLinkFour: 3,
  emotionalLinkFive: 4,
  emotionalLinkSix: 5,
  emotionalLinkSeven: 6,
  emotionalLinkEight: 7,
  emotionalLinkNine: 8,
  emotionalLinkTen: 9,
  emotionalLinkEleven: 10,
  emotionalLinkTwelve: 11,
  emotionalLinkThirteen: 12,
  emotionalLinkFourteen: 13,
  emotionalLinkFifteen: 14,
}

export const COGNITIVE_EMOTIONAL_WELLBEING_LINKS = Object.freeze({
  [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveAnxiety]: [
    Links.emotionalLinkOne,
    Links.emotionalLinkTwo,
    Links.emotionalLinkThree,
  ],
  [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveEmotionalWellbeing]: [
    Links.emotionalLinkOne,
    Links.emotionalLinkTwo,
    Links.emotionalLinkThree,
  ],
  [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveEmotionalWellbeingNo]: [
    Links.emotionalLinkOne,
    Links.emotionalLinkTwo,
    Links.emotionalLinkThree,
  ],
  [COGNITIVE_EMOTIONAL_WELLBEING.recCognitiveDepression]: [
    Links.emotionalLinkOne,
    Links.emotionalLinkTwo,
    Links.emotionalLinkThree,
  ],
  [COGNITIVE_EMOTIONAL_WELLBEING.recEmotionalMentalIllness]: [
    Links.emotionalLinkOne,
    Links.emotionalLinkTwo,
    Links.emotionalLinkThree,
  ],
})

export const COGNITIVE_SLEEP_LINKS = Object.freeze({
  [COGNITIVE_SLEEP.recCognitiveSleepIssuesNo]: [
    Links.emotionalLinkFour,
    Links.emotionalLinkFive,
  ],
  [COGNITIVE_SLEEP.recCognitiveSleepIssues]: [
    Links.emotionalLinkFour,
    Links.emotionalLinkFive,
  ],
})
