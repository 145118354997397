export const SOCIAL_KEYS = Object.freeze({
  recSocialGood: 'rec_social_good',
  recSocialBad: 'rec_social_bad',
})

const Links = Object.freeze({
  socialLinkOne: 0,
  socialLinkTwo: 1,
  socialLinkThree: 2,
  socialLinkFour: 3,
  socialLinkFive: 4,
  socialLinkSix: 5,
  socialLinkSeven: 6,
  socialLinkEight: 7,
  socialLinkNine: 8,
})

export const SOCIAL_ACTIVITY_LINKS = Object.freeze({
  [SOCIAL_KEYS.recSocialGood]: [
    Links.socialLinkOne,
    Links.socialLinkThree,
    Links.socialLinkFour,
  ],
  [SOCIAL_KEYS.recSocialBad]: [
    Links.socialLinkTwo,
    Links.socialLinkThree,
    Links.socialLinkFour,
  ],
});

export const SOCIAL_ACTIVITY_WORKSHEET = Object.freeze({
  [SOCIAL_KEYS.recSocialGood]: [
    Links.socialLinkOne,
    Links.socialLinkTwo,
  ],
  [SOCIAL_KEYS.recSocialBad]: [
    Links.socialLinkOne,
    Links.socialLinkTwo,
  ],
});